<template>
  <div class="app-container">
    {{ data }}
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import { userList } from "@/api/user";

export default {
  mixins: [mainCommon],
  data() {
    return {
      data: [],
    };
  },
  created() {
    this.HandleGetList();
  },
  methods: {
    HandleGetList() {
      userList().then((res) => {
        this.data = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 659px) {
}
</style>